import moment from "moment";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { Col } from "reactstrap";
import { replicationSelectValues, statusFilter } from "../../utils/Data";
import { filterValuesInIdeasFilter } from "../../utils/helpers";
import CustomFilter from "../CustomFilter/CustomFilter";
import CustomInputForDatePicker from "../CustomInputForDatePicker/CustomInputForDatePicker";

function IdeaPageFilter({ updateFilteredData, factoryBusinessUnits, areaSections, selectedIdeaFilter, isNewTask }) {
    const { t, i18n } = useTranslation();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);
    const onChange = (dates) => {
        const [start, end] = dates;
        updateFilteredData({
            createdAt: {
                $gte: moment(start).startOf('day').toISOString(),
                $lte: moment(end).endOf('day').toISOString()
            },
        });
        setStartDate(start);
        setEndDate(end);
    };
    return (
        <div style={{ display: "flex", gap: "50px" }}>
            {!isNewTask ? <>
                {/* filter by status */}
                <CustomFilter
                    defaultValueKey='status'
                    filterId='exampleFormControlSelect3'
                    filterLabel='idea.filterByIdeaStatus'
                    filterOptions={statusFilter}
                    updateFilterKey='status'
                    updateFilterFunction={updateFilteredData}
                />

                {/* Filter by replication */}
                <CustomFilter
                    defaultValueKey='replication'
                    filterId='replicationStatus'
                    filterLabel='idea.filterByReplication'
                    filterOptions={replicationSelectValues}
                    updateFilterKey='replication'
                    updateFilterFunction={updateFilteredData}
                />
            </> : null}
            {/* filter by factoryBusinessUnit */}
            <CustomFilter
                defaultValueKey='factoryBusinessUnit'
                filterId='filterByfactoryBusinessUnit'
                filterLabel='idea.FilterByFactoryBusinessUnit'
                filterOptions={factoryBusinessUnits?.map((el) => {
                    return {
                        value: el._id,
                        label: el.name[i18n.language] || el.name?.['en'],
                    };
                })}
                updateFilterKey='factoryBusinessUnit'
                updateFilterFunction={updateFilteredData}
                isMulti
            />

            {/* filter by areaSection */}
            <CustomFilter
                defaultValueKey='areaSection'
                filterId='filterByAreaSection'
                filterLabel='idea.filterByAreaSection'
                filterOptions={filterValuesInIdeasFilter(areaSections, 'factoryBusinessUnit', selectedIdeaFilter)?.map((el) => {
                    return {
                        value: el._id,
                        label: el.name[i18n.language] || el.name?.['en'],
                    };
                })}
                updateFilterKey='areaSection'
                updateFilterFunction={updateFilteredData}
                isMulti
            />


            {/* Filter by date */}

            {/* <Col className="mb-3" md="3" xs="12">
                <label
                    className="form-control-label"
                    htmlFor='filterByDate'
                >
                    {t('idea.filterByDate')}
                </label>
                <DatePicker
                    id="filterByDate"
                    wrapperClassName='d-block'
                    selected={startDate}
                    onChange={onChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    customInput={<CustomInputForDatePicker />}
                    dateFormat="dd/MM/yyyy"
                />
            </Col> */}
        </div>
    )
}

export default IdeaPageFilter